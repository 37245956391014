import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { PageProps } from "gatsby"

import { MDXProvider } from "@mdx-js/react"

import Layout from "../layout/Layout"
import PageWrapper from "../layout/PageWrapper"

import MyArticleTable from "./MyArticleTable"
import MyArticleImg from "./MyArticleImg"
import MyArticleMore from "./MyArticleMore"
import SEO from "../SEO";
import Article from "./Article"

const components = {
  img: MyArticleImg,
  More: MyArticleMore,
  table: MyArticleTable,
};

type Props = {
  data: {
    mdx: {
      id: string;
      body: string;
      frontmatter: {
        author?: string;
        authorsTitle?: string;
        date?: string;
        sources: {
          link: string;
          label: string;
        }[],
        tags: string[];
        title?: string;
        description?: string;
        keywords: string[];
        rawDate: string;
      }
    }
  };
} & PageProps;

const BlogPost = ({ data, location }: Props) => {
  const { mdx: post } = data

  const keywords = React.useMemo(() => {
    const arr = post.frontmatter.keywords
    return arr.length ? arr.join(", ") : "";
  }, [post]);

  return (
    <MDXProvider components={components}>
      <Layout location={location}>
        <Helmet bodyAttributes={{ "type": "blog" }} />
        <SEO
          title={post.frontmatter.title}
          description={post.frontmatter.description}
          keywords={keywords}
          date={post.frontmatter.rawDate}
        />
        <PageWrapper align="left">
          <Article
            content={post.body}
            date={post.frontmatter.date}
            author={post.frontmatter.author}
            authorsTitle={post.frontmatter.authorsTitle}
            sources={post.frontmatter.sources}
            tags={post.frontmatter.tags}
            title={post.frontmatter.title}
          />
        </PageWrapper>
      </Layout>
    </MDXProvider>
  )
}

BlogPost.propTypes = {
  data: PropTypes.shape({
    mdx: PropTypes.object,
  }),
}

export default BlogPost

