import { graphql } from "gatsby"

import BlogPost from "../components/cms/BlogPost";

export default BlogPost;

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    mdx(id: { eq: $id }) {
      id
      body
      frontmatter {
        date(formatString: "DD.MM.YYYY, hh:mm")
        rawDate: date
        title
        description
        keywords
        tags
        sources {
          link,
          label
        },
        author,
        authorsTitle
      }
    }
  }
`;
